$breakpoint: 772px;



#root {
  white-space: pre-line;
}

body{
  font-size: 18px;
}


.btn:focus{
  box-shadow: 0 0 0 0.2rem transparent;
}
.main-bg {
  background: linear-gradient(40.53deg, #41bae0 0%, #b3e3f3 91.35%);
}
.light-grey-bg {
  background-color: #f5f5f5;
}
.img-cover{
    object-fit: cover;
}
.img-contain{
    object-fit: contain;
}

section {
  padding-block: 60px;
}

.text-container {
  max-width: 800px;
}

.bg-transparent {
  background-color: transparent !important;
}

/* Navbar Start*/
nav.navbar {
  transition: all 0.3s ease-in-out;
}

.rowToCol {
  display: flex;
  width: auto;
  //background: red;
  justify-content: space-around;
  flex-direction: row;
  //.d-flex justify-content-around flex-row
}

@media screen and (max-width: $breakpoint) {
  .navbar-collapse {
    background-color: var(--bs-white);
    padding-inline: 16px;
    border-radius: 16px;
    &:first-child {
      margin-top: 16px;
    }
    & > a {
      margin-bottom: 16px;
    }
  }

  .rowToCol {
    display: flex;
    width: auto;
    //background: blue;
    flex-direction:column;
    padding: 10px;
    margin: 20px;
    justify-content: space-between;
    align-items: center;
    //.d-flex justify-content-around flex-row
  }
}
/* Navbar End*/


/* Footer Start */
#footer {
  background-color: $primary;
}
/* Footer End */

// FAQ
.accordion-item {
  border-color: transparent;
}

.accordion-button {
  box-shadow: none;

  outline: none;

  img {
    transition: transform 0.3s ease-in-out;

    transform: rotate(-90deg);
    margin: -5px 30px auto 20px;
  }

  &:not(.collapsed) {
    opacity: 0.8;
    box-shadow: none;

    img {
      transform: rotate(90deg);
    }
  }

  &::after {
    display: none;
  }

  &:focus {
    border-color: transparent;
  }
}

@media screen and (max-width: $breakpoint) {
  .accordion-header {
    .fs-1 {
      font-size: calc(1.1rem + 0.7vw) !important;
      line-height: calc(2rem + 0.9vw) !important;
    }

    img {
      width: 20px;
      height: 20px;
    }
  }
}

.transition-all {
  transition: all 0.3s ease-in-out;
}



.dashboard{
  @extend .light-grey-bg;

  .sidebar{
    width: 300px;
    background: white;
    height: 100vh;
    overflow-y: auto;
    padding: 16px 24px;
  }

  main{
    padding: 16px 24px;

    .content{
        padding: 16px 24px;
        background: white;
        height: calc(100vh - 32px);
        overflow-y: auto;
    }
  }
}

@media screen and (max-width: $breakpoint) {
  .dashboard{
    flex-direction: column;

    .sidebar{
      width: 100%;
      height: auto;
    }

    main{
      .content{
        height: auto;
      }
    }
  }

}